<template>
    <div>
        <b-button variant="success"
                  @click="$router.push({name: 'SablonEkleVeyaDuzenle'})"
                  class="mb-3" style="float: right; padding: 10px;">
            <b-icon icon="plus"></b-icon>
            Şablon Ekle
        </b-button>

        <div style="clear:both;"></div>
        <b-table stacked="sm"
                 :busy="isBusy"
                 :items="items"
                 :fields="fields">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                </div>
            </template>
            <template v-slot:cell(metin)="data">
                <div class="m-2 mobil-metin">{{data.item.metin}}</div>
            </template>
            <template v-slot:cell(islemler)="data">
                <b-button class="m-2"
                          @click="$router.push({name: 'TweetCevapla', params: {sablonId: data.item.id }})"
                          variant="primary">
                    <b-icon icon="reply"></b-icon>
                    Tweet Cevapla
                </b-button>

                <b-button class="m-2" variant="success"
                          @click="tweetModalGoster(data.item.id)">
                    Tweet At
                    <b-icon icon="cursor"></b-icon>
                </b-button>

                <b-button variant="info"
                          class="m-2"
                          @click="duzenle(data.item.id)">
                    <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button class="m-2" variant="danger"
                          @click="sil(data.item.id)">
                    <b-icon icon="x-circle"></b-icon>
                </b-button>
            </template>
        </b-table>
        <b-modal ref="tweetAttirModal"
                 title="Tweet Attırma İşlemi"
                 @ok="tweetle"
                 ok-title="Tamam"
                 cancel-title="İptal Et">
            <p>Tweet Attırma iznini <strong>{{this.tweetYazmaIzniVerenSayisi}}</strong> kişi vermiş.</p>

            <label class="mt-3" for="kullaniciSayisi">Kullanici Sayisi</label>
            <b-form-input id="kullaniciSayisi"
                          v-model="kullaniciSayisi"></b-form-input>

            <label class="mt-3" for="kacDakikadaYapilsin">Kaç Dakikada Yapılacak</label>
            <b-form-input id="kacDakikadaYapilsin"
                          v-model="kacDakikadaYapilacak"></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    import {HepsiniGetir} from "@/api/sablon"
    import {BasariliMesajiniGoster, HataliMesajiniGoster} from "@/messages"
    import {Sil} from "@/api/sablon"
    import {Tweetle} from "@/api/tweet"
    import {TweetAtmaIziniVerenSayisi} from "@/api/hesap"

    export default {
        name: "Sablonlarim",
        data() {
            return {
                isBusy: false,
                fields: [
                    {key: 'metin', label: 'Metin'},
                    {key: 'islemler', label: 'İşlemler'}
                ],
                items: [],

                islemYapilacakSablonId: 0,
                tweetYazmaIzniVerenSayisi: 0,
                kullaniciSayisi: '',
                kacDakikadaYapilacak: '',
            }
        },
        methods: {
            async tweetle(islem) {

                if (this.kullaniciSayisi === '')
                    return HataliMesajiniGoster("Kullanıcı Sayısı Boş Bırakılamaz!")

                if (this.kacDakikadaYapilacak === '')
                    return HataliMesajiniGoster("Dakikayı Boş Bırakamazsınız!");

                if (this.tweetYazmaIzniVerenSayisi < this.kullaniciSayisi)
                    return HataliMesajiniGoster("O Kadar izin veren kişi yok!");

               const res = await Tweetle(
                    this.islemYapilacakSablonId,
                    this.$store.getters.kisi.id,
                    this.kullaniciSayisi,
                    this.kacDakikadaYapilacak
                )
                BasariliMesajiniGoster(res)

                this.islemYapilacakSablonId = 0
                this.kullaniciSayisi = ''
                this.kacDakikadaYapilacak = ''
            },
            tweetModalGoster(sablonId) {
                this.islemYapilacakSablonId = sablonId
                this.$refs['tweetAttirModal'].show()
            },
            duzenle(sablonId) {
                this.$router.push({name: 'SablonEkleVeyaDuzenle', params: {sablonId}})
            },
            async sil(sablonId) {
                const {message} = await Sil(sablonId)
                BasariliMesajiniGoster(message)
                this.sablonlariGetir()
            },
            async sablonlariGetir() {
                this.isBusy = true;
                const {data} = await HepsiniGetir()
                this.items = data;
                this.isBusy = false;
            }
        },
        async created() {
            this.sablonlariGetir()
            this.tweetYazmaIzniVerenSayisi = await TweetAtmaIziniVerenSayisi()
        }
    }
</script>

<style scoped lang="scss">
    .mobil-metin {
        @media (max-width: 500px) {
            margin-top: 0px !important;
        }
    }
</style>

import http from '@/request'

export const Getir = (sablonId) => {
    return http.request({
        url: 'Sablon/Getir',
        params: {
            sablonId
        }
    })
}

export const HepsiniGetir = () => {
    return http.request({
        url: 'Sablon/HepsiniGetir'
    })
}

export const EkleVeyaDuzenle = (sablonVm) => {
    return http.request({
        url: 'Sablon/EkleVeyaDuzenle',
        method: 'post',
        data: sablonVm
    })
}

export const Sil = (sablonId) => {
    return http.request({
        url: 'Sablon/Sil',
        method: 'delete',
        params: {
            sablonId
        }
    })
}
